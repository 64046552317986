<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="editProductForm"
        ref="editProductForm"
        class="custom-form pt-8"
        @submit.prevent="submitEditedProduct"
      >
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="product.containerTypeId"
              v-bind="autocompleteAttrs"
              :items="containerTypes"
              label="Typ kontenera"
              placeholder="Typ kontenera"
            />
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              v-model="product.debrisTypeId"
              :items="sortedDebrisTypes"
              :search-input="debrisSearchValue"
              label="Typ odpadu"
              placeholder="Typ odpadu"
              v-bind="autocompleteAttrs"
              :item-text="getDebrisString"
              :filter="searchDebrisType"
              @keydown.enter.native.prevent="debrisSearchValue = ''"
              @update:search-input="(value) => { debrisSearchValue = value }"
            >
              <template #selection="{ item, index:number }">
                <MultiselectChoices
                  :index="number"
                  :label="item.code"
                  :array-length="product.debrisTypeId.length"
                  :visible-items="5"
                />
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model.number="product.netValue"
              :rules="[rules.required, rules.money, rules.lowerThan(10000001)]"
              outlined
              type="number"
              label="Cena netto"
              placeholder="Wpisz cenę netto [zł]"
              @wheel="$event.target.blur()"
            />
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="product.reason"
              outlined
              label="Powód zmiany"
              placeholder="Wpisz powód zmiany"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="editProductForm"
      >
        Edytuj produkt
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import MultiselectChoices from '../Elements/MultiselectChoices'
import rules from '../../utils/validators'
import { parseAsBasicUnit, searchDebrisType, getDebrisString } from '../../utils'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper,
    MultiselectChoices,
  },
  props: {
    editingProduct: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      rules,
      debrisSearchValue: '',
      product: {
        containerTypeId: null,
        debrisTypeId: null,
        netValue: null,
      },
      autocompleteAttrs: {
        itemText: 'name',
        itemValue: 'id',
        outlined: true,
        dense: true,
        rules: [rules.required]
      }
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.client.isProcessing,
      containerTypes: state => state.containerTypes.items,
      sortedDebrisTypes: state => state.debrisTypes.items.slice().sort((a, b) => a.name?.localeCompare(b.name)),
    }),
  },
  mounted () {
    this.getContainerTypes()
    this.getDebrisTypes({ params: { aggregate: false } })
    const { id, netValue, containerType, debrisType } = this.editingProduct
    this.product = {
      id,
      debrisTypeId: debrisType.id,
      containerTypeId: containerType.id,
      netValue: netValue / 100,
      reason: ''
    }
  },
  methods: {
    ...mapActions({
      getContainerTypes: 'containerTypes/getItems',
      getDebrisTypes: 'debrisTypes/getItems',
      getAggregateTypes: 'aggregateTypes/getItems',
      editProduct: 'client/editProduct',
      closeDialog: 'layout/closeDialog',
      showSnackbar: 'snackbar/showSnackbar'
    }),
    searchDebrisType,
    getDebrisString,
    productCallback (message) {
      this.showSnackbar({ message })
      this.closeDialog()
    },
    submitEditedProduct () {
      if (this.$refs.editProductForm.validate()) {
        const netValue = parseAsBasicUnit(this.product.netValue)

        this.editProduct({
          ...this.product,
          netValue
        }).then(this.productCallback(['Zaktualizowano produkt']))
      }
    },
  }
}
</script>
