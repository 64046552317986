import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogFormWrapper',{scopedSlots:_vm._u([{key:"form",fn:function(){return [_c(VForm,{ref:"editProductForm",staticClass:"custom-form pt-8",attrs:{"id":"editProductForm"},on:{"submit":function($event){$event.preventDefault();return _vm.submitEditedProduct.apply(null, arguments)}}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,_vm._b({attrs:{"items":_vm.containerTypes,"label":"Typ kontenera","placeholder":"Typ kontenera"},model:{value:(_vm.product.containerTypeId),callback:function ($$v) {_vm.$set(_vm.product, "containerTypeId", $$v)},expression:"product.containerTypeId"}},'v-autocomplete',_vm.autocompleteAttrs,false))],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,_vm._b({attrs:{"items":_vm.sortedDebrisTypes,"search-input":_vm.debrisSearchValue,"label":"Typ odpadu","placeholder":"Typ odpadu","item-text":_vm.getDebrisString,"filter":_vm.searchDebrisType},on:{"update:search-input":function (value) { _vm.debrisSearchValue = value }},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();_vm.debrisSearchValue = ''}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var number = ref.index;
return [_c('MultiselectChoices',{attrs:{"index":number,"label":item.code,"array-length":_vm.product.debrisTypeId.length,"visible-items":5}})]}}]),model:{value:(_vm.product.debrisTypeId),callback:function ($$v) {_vm.$set(_vm.product, "debrisTypeId", $$v)},expression:"product.debrisTypeId"}},'v-autocomplete',_vm.autocompleteAttrs,false))],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"rules":[_vm.rules.required, _vm.rules.money, _vm.rules.lowerThan(10000001)],"outlined":"","type":"number","label":"Cena netto","placeholder":"Wpisz cenę netto [zł]"},on:{"wheel":function($event){return $event.target.blur()}},model:{value:(_vm.product.netValue),callback:function ($$v) {_vm.$set(_vm.product, "netValue", _vm._n($$v))},expression:"product.netValue"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","label":"Powód zmiany","placeholder":"Wpisz powód zmiany"},model:{value:(_vm.product.reason),callback:function ($$v) {_vm.$set(_vm.product, "reason", $$v)},expression:"product.reason"}})],1)],1)],1)]},proxy:true},{key:"submit",fn:function(){return [_c(VBtn,{staticClass:"base-hover",attrs:{"color":"primary","loading":_vm.isProcessing,"type":"submit","form":"editProductForm"}},[_vm._v(" Edytuj produkt ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }